export type trackEventProps = {
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
};

/**
 * Track event if utag is defined
 * @param event
 */
export const trackEvent = ({
  eventCategory,
  eventAction,
  eventLabel,
}: trackEventProps) => {
  if (!eventCategory || !eventAction || !eventLabel) {
    return;
  }
  if (typeof window.utag === "undefined") {
    return;
  }

  window.utag.link({
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export default {
  trackEvent,
};
