import React from "react";
import { Link } from "gatsby";
import { pick } from "lodash";

type Props = {
  to: string,
  charset?: string,
  coords?: string,
  download?: string,
  hreflang?: string,
  media?: string,
  name?: string,
  ping?: string,
  referrerpolicy?: string,
  rel?: string,
  rev?: string,
  shape?: string,
  target?: string,
  type?: string,
};

const aAllowedProps = [
  "charset",
  "coords",
  "download",
  "href",
  "hreflang",
  "media",
  "name",
  "ping",
  "referrerpolicy",
  "rel",
  "rev",
  "shape",
  "target",
  "type",
];

const SafeLink = (props: Props) => {
  return (props.to || "").match(/:\/\//) ? (
    <a {...pick(props, aAllowedProps)} href={props.to}>
      {props.children}
    </a>
  ) : (
    <Link {...props}>{props.children}</Link>
  );
};

export default SafeLink;
