/*global Sentry*/
import React, { Component } from "react";

type Props = {
  children?: React.node,
  component?: boolean,
  silent?: boolean,
  message?: string,
};

export default class ErrorBoundary extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (this.props.silent) return;
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    const { component, children, message } = this.props;
    return this.state.error && component ? (
      <span>{message ? message : "Component did not load"}</span>
    ) : (
      children
    );
  }
}
