import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import type { Crumb } from "../Breadcrumbs/Breadcrumbs";

type Props = {
  style?: CSSStyleDeclaration,
  heading?: string,
  lead?: string | Array<string>,
  breadcrumbs?: Array<Crumb>,
  className?: string,
  bbrr?: boolean,
  bblr?: boolean,
  btrr?: boolean,
  btlr?: boolean,
  children?: React.Node,
  HeadingTag?: string,
  column?: boolean,
  banner?: boolean,
  component?: string,
};

const Section = ({
  style = {},
  heading,
  lead,
  breadcrumbs,
  className,
  btrr,
  bbrr,
  bblr,
  btlr,
  children,
  HeadingTag = "h2",
  column,
  banner,
  component,
}: Props) => (
  <section
    className={
      "Section" +
      (className ? " " + className : "") +
      (btrr ? " btrr" : "") +
      (bbrr ? " bbrr" : "") +
      (bblr ? " bblr" : "") +
      (btlr ? " btlr" : "") +
      (column ? " column" : "") +
      (banner ? " dark bg-bsm-orange-4" : "")
    }
    style={style}
  >
    <Container
      className={`${column ? "column" : "page"}-width-container`}
      fluid
    >
      {breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null}
      {heading || lead ? (
        <Row
          className={
            "section-heading justify-content-center" +
            (component ? ` ${component}` : "")
          }
        >
          {heading ? (
            <Col xs={12} sm={column ? 12 : 8}>
              <HeadingTag>{heading}</HeadingTag>
            </Col>
          ) : null}
          {lead
            ? lead.map((string, i) => (
                <Col key={i} xs={12} sm={12}>
                  <p className="lead">{string}</p>
                </Col>
              ))
            : null}
        </Row>
      ) : null}
      <Row className="justify-content-center">{children}</Row>
    </Container>
  </section>
);

export default Section;
