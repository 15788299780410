import React, { useLayoutEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SEO from "../SEO/SEO";
import Favicon from "../Favicon/Favicon";
import { graphql, useStaticQuery } from "gatsby";
import type { TYoastSeo } from "../SEO/SEO";
import InjectEnvVars from "../InjectEnvVars/InjectEnvVars";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
// import CovidAnnouncement from "../CovidAnnouncement/CovidAnnouncement";
import { debounce } from "lodash";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type Props = {
  children: React.Node,
  menus?: any,
  solidNav?: boolean,
  seo?: Object<TYoastSeo>,
  location?: any,
};

const Template = ({ children, solidNav, menuData, seo, location }: Props) => {
  const menuDataFromStaticQuery = useStaticQuery(query);
  let menus = menuData ? menuData : menuDataFromStaticQuery;

  if (typeof document !== "undefined") {
    var timesRun = 0;
    var interval = setInterval(function() {
      var trustarc_banner = document.getElementById("truste-consent-track");
      timesRun += 1;
      if (timesRun === 10) {
        clearInterval(interval);
      }
      if (
        trustarc_banner !== null &&
        trustarc_banner.style.display !== "none"
      ) {
        clearInterval(interval);
        fireTrackEvents();
      }
    }, 1000);
    function fireTrackEvents() {
      var accept_button = document.getElementById("truste-consent-button"),
        manage_button = document.getElementById("truste-show-consent"),
        accept_fire,
        reject_fire;
      if (accept_button && !accept_fire) {
        accept_button.addEventListener("click", function() {
          trackEvent({
            eventCategory: "Trustarc",
            eventAction: "Button Click",
            eventLabel: "Accept Cookies",
          });
          accept_fire = true;
        });
      }
      if (manage_button && !reject_fire) {
        manage_button.addEventListener("click", function() {
          trackEvent({
            eventCategory: "Trustarc",
            eventAction: "Button Click",
            eventLabel: "Manage Cookies",
          });
          reject_fire = true;
        });
      }
    }
  }

  let heightRef = React.createRef();
  useLayoutEffect(() => {
    if (!heightRef.current) {
      return;
    }
    const percentageObj = {};
    const percentagesArr = [25, 50, 75, 100];
    window.addEventListener("scroll", debounce(trackScroll, 20));
    function trackScroll(event) {
      const scrollVal = window.scrollY + window.innerHeight;
      if (!heightRef.current) return;
      const maxScrollHeight = heightRef.current.offsetHeight;
      percentagesArr.forEach(percentage => {
        const scrollPercentage = parseInt((maxScrollHeight / 100) * percentage);
        if (
          scrollVal >= scrollPercentage &&
          !percentageObj[scrollPercentage.toString()]
        ) {
          trackEvent({
            eventCategory: capitalizeEachWordOfPathname(
              window.location.pathname === "/"
                ? "Homepage"
                : window.location.pathname
            ),
            eventAction: "Scroll Depth",
            eventLabel: percentage.toString() + "%",
          });
          percentageObj[scrollPercentage.toString()] = true;
        }
      });
    }
    return () => {
      window.removeEventListener("scroll", trackScroll);
    };
  }, [heightRef]);
  return (
    <ErrorBoundary>
      <div ref={heightRef}>
        <InjectEnvVars />
        <Favicon />
        <SEO {...seo} location={location} />
        <Header solidNav={solidNav} menus={menus} />
        {/* <CovidAnnouncement /> */}
        <main role="main">{children}</main>
        <Footer menus={menus} />
      </div>
    </ErrorBoundary>
  );
};

export default Template;

const query = graphql`
  query {
    headerLeft: file(name: { eq: "header-left" }) {
      ...ChildMenuJsonFragment
    }
    headerRight: file(name: { eq: "header-right" }) {
      ...ChildMenuJsonFragment
    }
    footer: file(name: { eq: "footer" }) {
      ...ChildMenuJsonFragment
    }
  }
`;

export const childMenuJsonFragment = graphql`
  fragment ChildMenuJsonFragment on File {
    childMenuJson {
      menus {
        edges {
          node {
            id
            menuItems {
              edges {
                node {
                  childItems {
                    edges {
                      node {
                        id
                        label
                        url
                      }
                    }
                  }
                  id
                  label
                  url
                }
              }
            }
          }
        }
      }
    }
    name
  }
`;
