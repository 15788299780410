export const capitalizeEachWordOfPathname = pathname =>
  pathname
    .replace(/\//g, " ")
    .replace(/-/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .trim();

export default { capitalizeEachWordOfPathname };
