import React from "react";
import { Helmet } from "react-helmet";

const Favicon = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/apple-touch-icon.png?v=rMq5jaEgGX"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon-32x32.png?v=rMq5jaEgGX"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon-16x16.png?v=rMq5jaEgGX"
    />
    <link rel="manifest" href="/site.webmanifest?v=rMq5jaEgGX" />
    <link
      rel="mask-icon"
      href="/safari-pinned-tab.svg?v=rMq5jaEgGX"
      color="#f9812a"
    />
    <link rel="shortcut icon" href="/favicon.ico?v=rMq5jaEgGX" />
    <meta name="msapplication-TileColor" content="#f9812a" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
);

export default Favicon;
