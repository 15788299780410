import React from "react";
import { Col, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../../../static/images/svg/logo.svg";
import SafeLink from "../SafeLink/SafeLink";
import { Button } from "react-bootstrap";
import { trackEvent } from "../../utils/tealium";

type Props = {
  solidNav?: boolean,
  menus?: any,
};

const Header = ({ solidNav, menus }: Props) => (
  <header className={"Header" + (solidNav ? " solid-nav" : "")}>
    <Container className="page-width-container header-container">
      <Navbar expand="lg" className="no-gutters">
        <Col sm={2} xs={6} className="brand-container">
          <Navbar.Brand as={SafeLink} to="/" href="/">
            <img src={logo} className="header-logo" alt="logo" />
          </Navbar.Brand>
        </Col>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          data-test="mobile-nav-toggle"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {menus.headerLeft &&
            menus.headerLeft.childMenuJson.menus.edges.length
              ? menus.headerLeft.childMenuJson.menus.edges[0].node.menuItems.edges.map(
                  ({ node }) => {
                    return node.childItems.edges.length ? (
                      <NavDropdown
                        id={node.id}
                        key={node.id}
                        href={node.url}
                        title={node.label}
                      >
                        {node.childItems.edges.map(({ node: childNode }) => (
                          <NavDropdown.Item
                            key={childNode.id}
                            as={SafeLink}
                            activeClassName="active"
                            to={childNode.url}
                            onClick={() => {
                              trackEvent({
                                eventCategory: "Navbar",
                                eventAction: "Link Click",
                                eventLabel: `${node.label} - ${childNode.label}`,
                              });
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: childNode.label }}
                            />
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : (
                      <Nav.Link
                        as={SafeLink}
                        activeClassName="active"
                        to={node.url}
                        key={node.id}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: node.label }}
                        />
                      </Nav.Link>
                    );
                  }
                )
              : null}
          </Nav>
          <Nav>
            {menus.headerRight &&
            menus.headerRight.childMenuJson.menus.edges.length
              ? menus.headerRight.childMenuJson.menus.edges[0].node.menuItems.edges.map(
                  ({ node }) => {
                    return node.childItems.edges.length ? (
                      <NavDropdown
                        key={node.id}
                        href={node.url}
                        title={node.label}
                      >
                        {node.childItems.edges.map(({ node }) => (
                          <NavDropdown.Item
                            as={SafeLink}
                            to={node.url}
                            activeClassName="active"
                            key={node.id}
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: node.label }}
                            />
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : (
                      <Nav.Link
                        key={node.id}
                        as={SafeLink}
                        to={node.url}
                        activeClassName="active"
                        data-test="header-contact-link"
                        onClick={() => {
                          trackEvent({
                            eventCategory: "Navbar",
                            eventAction: "Link Click",
                            eventLabel: node.label,
                          });
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: node.label }}
                        />
                      </Nav.Link>
                    );
                  }
                )
              : null}
          </Nav>
          <Button
            variant="outline-primary login-button"
            onClick={() => {
              trackEvent({
                eventCategory: "Navbar",
                eventAction: "Link Click",
                eventLabel: "Login",
              });
              window.location = window._env_.GATSBY_RETURNING_USER_JOURNEY_URI
            }}
          >
            Login
          </Button>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
);

export default Header;
