import React, { useState } from "react";
import { Button, Col, Form, Jumbotron, Row } from "react-bootstrap";
import TextInput from "../Form/TextInput/TextInput";
import homeHero from "./assets/home-hero.svg";
import errorHero from "./assets/404-hero.svg";
import contactHero from "./assets/contact-hero.svg";
import thanksHero from "./assets/thanks-hero.svg";
import locationHero from "./assets/location-hero.svg";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { trackEvent } from "../../utils/tealium";
import { capitalizeEachWordOfPathname } from "../../utils/capitalisePathname";

type Props = {
  svg?: any,
  imageUrl?: string,
  smallText?: string,
  largeText?: string,
  newUserJourney?: boolean,
  page?: string,
  buttonText?: string,
  buttonEventAction?: string,
  buttonEventLabel: string,
  svgTextReplacements?: Array<{ selector: string, text: string }>,
};

export default ({
  svg,
  imageUrl,
  smallText,
  largeText,
  newUserJourney,
  page,
  buttonText,
  buttonAction,
  buttonEventLabel,
  svgTextReplacements = [],
}: Props) => {
  const [postcode, setPostcode] = useState("");

  if (page === "home") svg = homeHero;
  if (page === "404") svg = errorHero;
  if (page === "contact") svg = contactHero;
  if (page === "thanks") svg = thanksHero;
  if (page === "location") svg = locationHero;

  const handleNewUserJourneyPostcodeChange = e => {
    setPostcode(e.target.value);
  };

  const handleNewUserJourneySubmit = e => {
    e.preventDefault();
    trackEvent({
      eventCategory: "Homepage",
      eventAction: "Button Click",
      eventLabel: "Get Prices",
    });
    let uri = window._env_.GATSBY_NEW_USER_JOURNEY_URI;
    window.location = uri + "?pickupPostcode=" + postcode;
  };

  const handleButtonClick = () => {
    if (buttonText) {
      trackEvent({
        eventCategory: capitalizeEachWordOfPathname(window.location.pathname),
        eventAction: "Button Click",
        eventLabel: buttonText,
      });
    }
    if (buttonAction.startsWith("javascript:")) {
      eval(buttonAction);
      return;
    }
    window.location = buttonAction;
  };

  return (
    <Col className={`Hero ${page}`}>
      <Row>
        <Col md>
          <Jumbotron>
            {["404", "thanks", "contact"].indexOf(page) > -1 ? (
              <>
                <h1
                  className="heading huge font-weight-bold text-nowrap"
                  dangerouslySetInnerHTML={{
                    __html: largeText
                      ? largeText.split(/\n+/).join("<br />")
                      : "",
                  }}
                />
                <p className="heading fs-bsm-18">{smallText}</p>
              </>
            ) : (
              <>
                <h1 className="tiny-h1">{smallText}</h1>
                <p
                  className="heading huge font-weight-bold text-nowrap"
                  dangerouslySetInnerHTML={{
                    __html: largeText
                      ? largeText.split(/\n+/).join("<br />")
                      : "",
                  }}
                />
              </>
            )}

            {newUserJourney ? (
              <Form
                className="hero-form"
                onSubmit={handleNewUserJourneySubmit}
                data-test="journey-form"
              >
                <Row className="align-items-center new-user-journey">
                  <Col>
                    <TextInput
                      placeholder="Pickup postcode"
                      icon="location"
                      data-test="journey-form-input"
                      onChange={handleNewUserJourneyPostcodeChange}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      type="submit"
                      data-test="journey-form-submit"
                    >
                      Get Prices
                    </Button>
                  </Col>
                </Row>
              </Form>
            ) : null}

            {buttonText ? (
              <Button
                variant="secondary"
                onClick={handleButtonClick}
                className="mt-5"
                data-testid={"hero-cta-button"}
              >
                {buttonText}
              </Button>
            ) : null}
          </Jumbotron>
        </Col>
        <Col
          md
          className={
            "hero-column" +
            (svg ? " svg-hero" : "") +
            (!svg && imageUrl ? " image-hero" : "")
          }
        >
          {svg ? (
            <div className="hero-svg">
              <SvgLoader path={svg}>
                {svgTextReplacements.map(({ selector, text }, i) => (
                  <SvgProxy key={i} selector={selector}>
                    {text}
                  </SvgProxy>
                ))}
              </SvgLoader>
            </div>
          ) : null}
          {!svg && imageUrl ? (
            <div className="hero-image">
              <img src={imageUrl} alt="" className="d-md-none" />
            </div>
          ) : null}
        </Col>
        {!svg && imageUrl ? (
          <div className="mask">
            <div
              className="image"
              style={{ backgroundImage: `url("${imageUrl}")` }}
            />
          </div>
        ) : null}
      </Row>
    </Col>
  );
};
