import React, { Component } from "react";

export type TTextInput = {
  label?: string,
  placeholder?: string,
  icon?: string,
  name: string,
  InputType: React.Component,
  value?: string,
};

type Props = {
  label?: string,
  placeholder?: string,
  onChange?: Function,
  icon?: string,
  sm?: boolean,
  name?: string,
};

class TextInput extends Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      focus: false,
    };
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });
  }

  render() {
    let { focus } = this.state;
    let {
      label,
      placeholder,
      icon,
      sm,
      name,
      onChange,
      ...restProps
    } = this.props;
    return (
      <div
        className={
          "TextInput" +
          (focus ? " focus" : "") +
          (icon ? ` bsm-icon-${icon}` + (sm ? "-sm" : "") : "") +
          (" bsm-text-input" + (sm ? "-sm" : "")) +
          (label ? " with-label" : "")
        }
      >
        {label ? <label>{label}</label> : null}
        <input
          className="form-control"
          type="text"
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          {...restProps}
        />
      </div>
    );
  }
}

export default TextInput;
