import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export type TYoastSeo = {
  title?: string,
  metaDesc?: string,
  focuskw?: string,
  metaKeywords?: string,
  metaRobotsNoindex?: string,
  metaRobotsNofollow?: string,
  opengraphTitle?: string,
  opengraphDescription?: string,
  opengraphAppId?: string,
  opengraphImage?: string,
  twitterTitle?: string,
  twitterDescription?: string,
  twitterImage?: string,
  twitterCardType?: string,
};

type Props = {
  ...TYoastSeo,
  location?: any,
};

const SEO = ({
  title,
  metaDesc,
  focuskw,
  metaKeywords,
  metaRobotsNoindex,
  metaRobotsNofollow,
  opengraphTitle,
  opengraphDescription,
  opengraphAppId,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterCardType,
  location,
}: Props) => {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            defaultDescription,
            defaultUrl,
            defaultImage,
            defaultTwitterUsername,
          },
        },
      }) => {
        // Update UTAG data layer
        if (typeof window !== "undefined" && window.utag_data) {
          window.utag_data.page_title = title;
        }

        let url = location ? location.href : defaultUrl;

        let robots = [
          metaRobotsNoindex ? "noindex" : "index",
          metaRobotsNofollow ? "nofollow" : "follow",
        ];

        let defImage = opengraphImage
          ? opengraphImage
          : twitterImage
          ? twitterImage
          : defaultImage; //todo

        let defTitle = title
          ? title
          : opengraphTitle
          ? opengraphTitle
          : twitterTitle
          ? twitterTitle
          : defaultTitle;

        let defDescription = metaDesc
          ? metaDesc
          : opengraphDescription
          ? opengraphDescription
          : twitterDescription
          ? twitterDescription
          : defaultDescription;

        return (
          <Helmet title={title}>
            <meta charset="UTF-8" />
            <meta name="description" content={metaDesc} />
            <meta name="image" content={defImage} />
            <meta name="robots" content={robots.join(", ")} />
            <meta
              name="google-site-verification"
              content={process.env.GATSBY_GOOGLE_SITE_VERIFICATION}
            />

            {/*opengraph*/}
            <meta
              property="og:title"
              content={opengraphTitle ? opengraphTitle : defTitle}
            />
            <meta
              property="og:description"
              content={
                opengraphDescription ? opengraphDescription : defDescription
              }
            />
            <meta
              property="og:image"
              content={opengraphImage ? opengraphImage : defImage}
            />
            <meta property="og:url" content={url} />
            {opengraphAppId ? (
              <meta property="fb:app_id" content={opengraphAppId} />
            ) : null}

            {/*twitter*/}
            <meta
              name="twitter:title"
              content={twitterTitle ? twitterTitle : defTitle}
            />
            <meta
              name="twitter:description"
              content={twitterDescription ? twitterDescription : defDescription}
            />
            <meta
              name="twitter:image"
              content={twitterImage ? twitterImage : defImage}
            />
            {twitterCardType ? (
              <meta name="twitter:card" content={twitterCardType} />
            ) : null}
            <meta name="twitter:name" content={defaultTwitterUsername} />
            <meta name="twitter:site" content={defaultTwitterUsername} />
            <link rel="canonical" href={url} />
            <link rel="alternate" href={url} hreflang="en" />
            <meta name="google-site-verification" content="_f8f_RJgYdwzwb5axXoyg3QMX66IBXeMecPL5dBu7aA" />
          </Helmet>
        );
      }}
    />
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: image
        defaultUrl: siteUrl
        defaultTwitterUsername: twitterUsername
      }
    }
  }
`;
