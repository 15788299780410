import React, { useState } from "react";
import Section from "../Section/Section";
import { Col } from "react-bootstrap";
import logo from "../../../static/images/svg/logo.svg";
import instagram from "./assets/icons/instagram.svg";
import facebook from "./assets/icons/facebook.svg";
import twitter from "./assets/icons/twitter.svg";
import SVG from "react-inlinesvg";
import Row from "react-bootstrap/Row";
import bart from "./assets/svg/bart.svg";
import SafeLink from "../SafeLink/SafeLink";
import { trackEvent } from "../../utils/tealium";

const socialIcons = [
  { svg: facebook, title: "Facebook", url: "https://www.facebook.com/bsm/" },
  { svg: twitter, title: "Twitter", url: "https://twitter.com/bsm_driving" },
  {
    svg: instagram,
    title: "Instagram",
    url: "https://www.instagram.com/bsmdriving",
  },
];

const Footer = ({ menus }) => {
  const [bartVisible, setBartVisible] = useState(false);
  return (
    <footer className="Footer">
      <div
        className="footer-corner"
        onClick={() => {
          trackEvent({
            eventCategory: "Footer",
            eventAction: "Interaction",
            eventLabel: "Easter Egg",
          });
          setBartVisible(!bartVisible);
        }}
      />
      <Section btlr className="footer-top">
        <Col sm={5} xs={5}>
          <h3 className="text-right">
            <a
              href="/"
              rel="nofollow"
              onMouseOver={e => {
                e.target.href = window._env_.GATSBY_NEW_USER_JOURNEY_URI;
              }}
              onClick={() => {
                trackEvent({
                  eventCategory: "Footer",
                  eventAction: "Link Click",
                  eventLabel: "Learn with BSM",
                });
              }}
            >
              Learn with BSM
            </a>
          </h3>
        </Col>
        <Col sm={2} xs={2}>
          <div className="footer-divider">&nbsp;</div>
        </Col>
        <Col sm={5} xs={5}>
          <h3>
            <SafeLink
              to="/become-a-franchisee/"
              onClick={() => {
                trackEvent({
                  eventCategory: "Footer",
                  eventAction: "Link Click",
                  eventLabel: "Earn with BSM",
                });
              }}
            >
              Earn with BSM
            </SafeLink>
          </h3>
        </Col>
      </Section>
      <Section className="footer-nav">
        <Col xs={12} md={3} className="footer-menu-column">
          <Row className="downward-break-cols align-items-end" noGutters>
            <Col sm={12}>
              <img src={logo} className="footer-logo" alt="logo" />
              <h6>
                <SafeLink
                  to="/contact"
                  onClick={() => {
                    trackEvent({
                      eventCategory: "Footer",
                      eventAction: "Link Click",
                      eventLabel: "Contact",
                    });
                  }}
                >
                  Contact
                </SafeLink>
              </h6>
            </Col>
            <Col sm={12} className="footer-social-icons">
              {socialIcons.map(({ title, svg, url }, i) => (
                <span key={i} className="footer-social-icon">
                  <a
                    href={url}
                    target={"_blank"}
                    title={title}
                    onClick={() => {
                      trackEvent({
                        eventCategory: "Footer",
                        eventAction: "Link Click",
                        eventLabel: title,
                      });
                    }}
                  >
                    <SVG src={svg} />
                  </a>
                </span>
              ))}
            </Col>
          </Row>
        </Col>
        {menus.footer && menus.footer.childMenuJson.menus.edges.length
          ? menus.footer.childMenuJson.menus.edges[0].node.menuItems.edges.map(
              ({ node }) => (
                <Col
                  key={node.id}
                  xs={12}
                  md={3}
                  className="footer-menu-column"
                >
                  <Row className="downward-break-cols" noGutters>
                    <Col sm={12}>
                      <span className="footer-nav-heading">
                        <span
                          dangerouslySetInnerHTML={{ __html: node.label }}
                        />
                      </span>
                    </Col>
                    <Col sm={12}>
                      <ul className="list-unstyled text-small">
                        {node.childItems.edges.map(({ node }) => (
                          <li
                            key={node.id}
                            className="footer-nav-item"
                            onClick={() => {
                              trackEvent({
                                eventCategory: "Footer",
                                eventAction: "Link Click",
                                eventLabel: node.label,
                              });
                            }}
                          >
                            <SafeLink to={node.url}>
                              <span
                                dangerouslySetInnerHTML={{ __html: node.label }}
                              />
                            </SafeLink>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              )
            )
          : null}
      </Section>
      <Section className="footer-copyright" bbrr>
        <Col md={3} className="d-sm-none d-md-block" />

        <Col sm={12} md={8}>
          <p className="copyright">
            Automobile Association Developments Limited is an appointed
            representative of Automobile Association Insurance Services Limited.
            Automobile Association Insurance Services Limited is authorised and
            regulated by the Financial Conduct Authority for the provision of
            consumer hire.
          </p>
          <p className="copyright">
            Copyright © 2023. British School of Motoring (BSM) is a trading name
            of Automobile Association Developments Limited. Registered office,
            Fanum House, Basing View, Basingstoke, Hampshire RG21 4EA.
            Registered in England and Wales number 01878835
          </p>
        </Col>
      </Section>
      {bartVisible ? <SVG className="bart" src={bart} /> : null}
    </footer>
  );
};

export default Footer;
