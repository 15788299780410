import React from "react";
import { Helmet } from "react-helmet";

/**
 * Manually include file env.js from the public root
 * @returns {*}
 * @constructor
 */
const InjectEnvVars = () => {
  return (
    <Helmet>
      <script src="/env.js" />
    </Helmet>
  );
};

export default InjectEnvVars;
