import React from "react";
import { Row } from "react-bootstrap";
import SafeLink from "../SafeLink/SafeLink";

export type Crumb = {
  title: string,
  linkTo: string,
};

type Props = {
  items?: Array<Crumb>,
};
const Breadcrumbs = ({ items }: Props) => {
  return items && items.length ? (
    <Row className="Crumbs justify-content-center">
      {items.map(({ title, to }, i) => {
        let Tag = to ? SafeLink : "span";
        return (
          <Tag key={i} to={to} className="crumb">
            {title}
          </Tag>
        );
      })}
    </Row>
  ) : null;
};

export default Breadcrumbs;
